<template>
  <v-dialog v-model="show" persistent scrollable max-width="800px">
    <v-card outlined>
      <v-system-bar color="grey lighten-2" height="32">
        <v-spacer />
        <!-- <v-btn icon small @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn> -->
        <v-icon color="red" class="mt-n2 mr-n2" large @click="show = false">
          mdi-close
        </v-icon>
      </v-system-bar>
      <v-card-title>
        {{ $t("Utilizator") }}
      </v-card-title>
      <v-card-text>
        <v-form ref="dataForm">
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                v-model="record.company"
                autofocus
                :label="$t('Companie')"
                item-text="name"
                item-value="_id"
                :items="companies"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="record.username" :label="$t('Utilizator')" :rules="inputRequired" />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="record.fullname" :label="$t('Nume prenume')" :rules="inputRequired" />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="record.email" :label="$t('Email')" :rules="inputRequired" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="record.password"
                type="password"
                :label="$t('Parolă')"
                :rules="inputRequiredPassword"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="record.level" :label="$t('Tip utilizator')" :rules="inputRequired" />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="record.settings.appType"
                :label="$t('Tip aplicație')"
                item-text="name"
                item-value="value"
                :items="companyTypes"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="record.settings.appTypes"
                :label="$t('Tipuri aplicație')"
                item-text="name"
                item-value="value"
                multiple
                :items="companyTypes"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="show = false">
          {{ $t("Anulează") }}
        </v-btn>
        <v-btn color="primary" @click="saveData">
          {{ $t("Salvează") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "../../plugins/axios"
import md5 from "js-md5"
import { EventBus } from "../../EventBus"

export default {
  components: {},
  data: () => ({
    recordId: "",
    appTypes: [],
    companies: [],
    record: {
      username: "",
      fullname: "",
      email: "",
      level: "",
      settings: {
        appType: "",
        appTypes: []
      },
      password: ""
    },
    etag: "",
    show: false,
    title: ""
  }),
  computed: {
    inputRequired() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    inputRequiredPassword() {
      const rule = []
      if (this.recordId === 0) rule.push(v => !!v || this.$t("required"))
      return rule
    },
    companyId() {
      return this.$store.getters.companyId
    },
    userId() {
      return this.$store.getters.accountId
    },
    companyTypes() {
      if (this.record.company) {
        const types = this.companies.find(itm => itm._id === this.record.company).appType
        return this.appTypes.filter(itm => types.includes(itm.value))
      }
      return []
    }
  },
  mounted() {
    axios.get('app_dictionary?where={"value":"appType"}').then(response => {
      this.$log("app_company reload", response)
      this.appTypes = response.data._items[0].content
      this.$log("appTypes ", this.appTypes)
    })
    axios.get("app_company").then(response => {
      this.$log("app_company reload", response)
      this.companies = response.data._items
      this.$log("appTypes ", this.companies)
    })
  },
  methods: {
    addData() {
      this.show = true
      this.recordId = 0
      this.record = {
        username: "",
        fullname: "",
        email: "",
        level: "",
        settings: {
          appType: "",
          appTypes: []
        },
        password: ""
      }

      this.$nextTick(() => this.$refs.dataForm.resetValidation())
    },
    editData(e) {
      this.show = true
      this.recordId = e._id
      this.etag = e._etag
      this.$log("etag ", e)
      axios.get("app_user/" + this.recordId).then(response => {
        this.$log("app_template", response)
        this.etag = response.data._etag
        this.record = response.data
        this.record.password = ""
      })
    },

    saveData() {
      this.$log("this.companyTypes", this.companyTypes)
      if (this.$refs.dataForm.validate()) {
        if (this.record.password) {
          this.record.password = md5(this.record.password)
        } else {
          this.record.password = undefined
        }
        this.record.token = md5(this.record.username)
        if (this.recordId === 0) {
          this.$log("file1 ", this.file)
          axios
            .post("app_user/", this.record)
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 201) {
                this.show = false
                this.$emit("reload")
              }
            })
            .catch(error => {
              this.$log("error ", error)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        } else {
          this.record._created = undefined
          this.record._etag = undefined
          this.record._links = undefined
          this.record._version = undefined
          this.record._latest_version = undefined
          this.record._updated = undefined
          axios
            .patch("app_user/" + this.recordId, this.record, {
              headers: {
                "If-Match": this.etag
              }
            })
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 200) {
                this.show = false
                this.$emit("reload")
              }
            })
            .catch(error => {
              this.$log("error ", error)
              // this.$log("error ", error._issues.content.name)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        }
      }
    }
  }
}
</script>
