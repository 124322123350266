<template>
  <v-card>
    <v-card-title>
      {{ $t("Utilizatori") }}
    </v-card-title>
    <v-card-text>
      <v-data-table class="elevation-1" :headers="headers" :items="items">
        <template v-slot:top>
          <div>
            <v-btn icon @click="reloadData">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn icon @click="addItem">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <add-user ref="addData" @reload="reloadData" />
  </v-card>
</template>
<script>
import axios from "../../plugins/axios"
import AddUser from "./AddUser"
export default {
  components: { AddUser },
  data: () => ({
    headers: [
      { text: "Utilizator", value: "username" },
      { text: "Nume prenume", value: "fullname" },
      { text: "Email", value: "email" },
      { text: "Tip", value: "level" },
      { text: "Tip aplicație", value: "settings.appType" },
      { text: "Acțiuni", value: "action", sortable: false }
    ],
    items: []
  }),
  computed: {
    companyId() {
      return this.$store.getters.companyId
    }
  },
  created() {
    this.reloadData()
  },
  methods: {
    editItem(item) {
      this.$refs.addData.editData(item)
    },
    addItem() {
      this.$refs.addData.addData()
    },
    deleteItem(item) {
      axios
        .delete("app_user/" + item._id, {
          headers: {
            "If-Match": item._etag
          }
        })
        .then(response => {
          // this.$log("schema delete", response)
          if (response.status == 204) this.reloadData()
        })
    },
    reloadData() {
      axios.get("app_user").then(response => {
        // this.$log("schema reload", response)
        this.items = response.data._items
      })
    }
  },
  $route(to) {
    if (to.name == "SettingsUsers") this.reloadData()
  }
}
</script>
